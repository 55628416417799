import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './masav-generator.screen.css';
import { MasavCharge } from './pages/charge-bank/charge-bank.screen';

export const MasavGenerator = () => {
    return (
        <>
            <h1>Masav Generator Screen</h1>
            <h1>Two tabs are requied חיובים + זיכויים</h1>

            <Tabs defaultActiveKey="הפקת קובץ חיובים" id="tools-tabs" className="tools-tabs">
                <Tab eventKey="הפקת קובץ חיובים" title="הפקת קובץ חיובים">
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            border: '1px solid black',
                            backgroundColor: 'white',
                            padding: '8px',
                            textAlign: 'left',
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}>
                        <thead
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderBottom: '2px solid black',
                            }}>
                            <tr>
                                <th style={{ padding: '8px', border: '1px solid black' }}>לקוח</th>
                                <th style={{ padding: '8px', border: '1px solid black' }}>קוד בנק</th>
                                <th style={{ padding: '8px', border: '1px solid black' }}>סניף</th>
                                <th style={{ padding: '8px', border: '1px solid black' }}>חשבון</th>
                                <th style={{ padding: '8px', border: '1px solid black' }}>I.D</th>
                                <th style={{ padding: '8px', border: '1px solid black' }}>סכום</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid black' }}>
                                    Nisan Center - איאת אגבאריה
                                </td>
                                <td style={{ padding: '8px', border: '1px solid black' }}>46</td>
                                <td style={{ padding: '8px', border: '1px solid black' }}>517</td>
                                <td style={{ padding: '8px', border: '1px solid black' }}>62917</td>
                                <td style={{ padding: '8px', border: '1px solid black' }}>200929511</td>
                                <td style={{ padding: '8px', border: '1px solid black' }}>(179 + 25 + 29 ) * 1.18 = 274.94</td>
                            </tr>
                        </tbody>
                    </table>
                    <a
                        href="https://www.masavit.com/GenerateMasav.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontWeight: 'bold', fontSize: '18px', color: '#702a72', textDecoration: 'none' }}>
                        לחץ כאן למעבר לאתר הפקת קובץ
                    </a>
                    <div style={{ fontWeight: 'bold', fontSize: '18px' }}>office@zenat.co.il</div>
                    <MasavCharge />
                </Tab>
                <Tab eventKey="הפקת קובץ זיכויים" title="הפקת קובץ זיכויים"></Tab>
            </Tabs>
        </>
    );
};
